import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'

import Hero from '../Hero'
import CTA from './partials/CTA'

import data from '../../temp-configs/home-data'

import styles from './HeroSection.module.scss'

const CTAList = ({ ctas, isHeroImageTest }) => {
    return (
        <div className={styles.ctaList}>
            {ctas.map((cta, index) => {
                return (
                    <CTA
                        key={`cta-${cta.link}`}
                        index={index}
                        label={cta.label}
                        link={cta.link}
                        selector={cta.selector}
                        isHeroImageTest={isHeroImageTest}
                    />
                )
            })}
        </div>
    )
}

CTAList.propTypes = {
    ctas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string
        })
    ).isRequired
}

const HeroSection = () => {
    const { isV1: isHeroImageTest } = useExperiment('EXP.HP_HERO_IMAGE.DIS-542')
    const { title, description, ctas } = data

    const heroClassNames = classNames(styles.homeHero, {
        [styles.homeHeroV1]: isHeroImageTest
    })
    const containerClassNames = classNames(styles.heroContainer, {
        [styles.heroContainerV1]: isHeroImageTest
    })
    const headingClassNames = classNames('t-heading1', styles.heading, {
        'col col--xs-12': !isHeroImageTest
    })
    const descriptionContainerClassNames = classNames({
        'col col--xs-12 u-flexAlign--center': !isHeroImageTest
    })
    const descriptionClassNames = classNames('t-heading3 col--xs-12', styles.description, {
        [styles.descriptionV1]: isHeroImageTest
    })

    return (
        <Hero className={heroClassNames} isHeroImageTest={isHeroImageTest}>
            <div className={containerClassNames}>
                <div>
                    <h1 className={headingClassNames}>
                        {title}
                    </h1>
                    <div className={descriptionContainerClassNames}>
                        <h3 className={descriptionClassNames}>
                            {description}
                        </h3>
                    </div>
                </div>
                <CTAList ctas={ctas} isHeroImageTest={isHeroImageTest} />
            </div>
        </Hero>
    )
}

export default HeroSection
